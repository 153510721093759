<template>
<nut-popup class="select-popup" v-model="showPopup" round position="bottom" :style="{ height: '80%' }" @close="close">
  <nut-searchbar class="select-ipt" v-model="keyword" placeText="Program name / ID" @submit="search"></nut-searchbar>
  <nut-infiniteloading class="select-list" @loadmore="onInfinite" :use-capture="true" :use-window="false" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="Loading" unload-more-txt="no more information">
    <div class="goods-item">
      <div class="goods-item-list" :class="{'active': item.is_select}" v-for="(item, index) in list" :key="index" @click="isSelect(item)">
        <div class="list-img">
          <img class="img-self" :src="item.cover_image" />
          <span class="goods-issp" v-if="item.issp === 1">ISSP</span>
        </div>
        <div class="list-info">
          <p class="info-title">{{item.en_name}}</p>
          <p class="info-text"><span class="text-color">Tuition: {{item.is_self === 1 ? item.tuition_last_fee : item.self_tuition_fee}}</span>({{item.is_self === 1 ? item.tuition_last_fee_unit : item.self_tuition_fee_unit}})</p>
          <p class="info-text">City: {{item.city}} {{item.province}}</p>
          <p class="info-text">Degree: {{item.education}}</p>
          <p class="info-text">Teaching language: {{item.language}}</p>
        </div>
      </div>
    </div>
  </nut-infiniteloading>
</nut-popup>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'selectGoodsList',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showPopup: false,
      list: [],
      total_pages: 1,
      page: 1,
      isHasMore: true,
      isLoading: false,
      selectList: [],
      keyword: ''
    }
  },
  components: {},
  computed: {},
  created () {
    this.getList()
  },
  watch: {
    show: {
      deep: true,
      handler () {
        this.showPopup = this.show
        this.page = 1
        this.list = []
        if (this.showPopup) {
          this.getList()
        }
      }
    }
  },
  methods: {
    search () {
      this.page = 1
      this.list = []
      this.getList()
    }, 
    // 获取商品列表
    getList () {
      let that = this
      Api.get({
        url: 'products',
        data: {
          limit: 10,
          page: this.page,
          keyword: this.keyword,
          is_pre_sale: 0
        },
        success (res) {
          if (res.code === 200 && res.data) {
            for (let i in res.data) {
              res.data[i].is_select = false
              that.list.push(res.data[i])
            }
            that.total_pages = res.total_pages ? Number(res.total_pages) : 0
          }
        }
      })
    },
    // 无限加载
    onInfinite () {
      this.page++
      if (this.page <= this.total_pages) {
        this.getList()
      } else {
        this.isHasMore = false
        return false
      }
    },
    // 关闭按钮
    close () {
      this.$emit('close')
      this.$emit('goodsCallback', this.selectList)
    },
    isSelect (item) {
      if (!item.deadline) {
        this.$toast.warn('The program has expired. Please select another program.')
        return false
      }
      item.is_select = !item.is_select
      if (item.is_select) {
        this.selectList.push(item)
      } else {
        for (let i in this.selectList) {
          if (!this.selectList[i].is_select) {
            this.selectList.splice(i, 1)
          }
        }
      }
    },
  }
}
</script>

<style scoped>
.select-popup{position:absolute;}
.select-ipt{width:100%;box-sizing:border-box;padding:20px 10px 10px;position:absolute;top:0;left:0;background-color:#fff;z-index:100;}
.select-list{padding-top:70px;}
.goods-item-list{padding-left:30px;background:url('~@/assets/images/apply-select.png') center left no-repeat;background-size:20px;}
.goods-item-list.active{background-image:url('~@/assets/images/apply-select-active.png');}
</style>
